import { useMutation, useQuery } from '@apollo/client';
import {
	Button,
	ComponentState,
	Divider,
	StatefulComponent,
	View,
} from '@bluebase/components';
import { useNavigation } from '@bluebase/core';
import {
	removeTypeName,
	TraitQuery,
	TraitQueryQuery,
	TraitUpdateMutation,
	TraitUpdateMutationMutation
} from '@mevris/client-graphql';
import React, { useCallback } from 'react';

import { TraitRuleEmptyState } from './TraitRuleEmptyState';
import { TraitProfileStateItem } from './TraitRuleListItem';

export interface TraitRuleListProps {
	traitId: string;
}

export const TraitRuleList = (props: TraitRuleListProps) => {
	const { traitId } = props;
	const { push } = useNavigation();

	if (!traitId) {
		return <ComponentState description="No Trait ID given" />;
	}

	const { loading, error, data } = useQuery<TraitQueryQuery>(
		TraitQuery,
		{ variables: { id: traitId } }
	);

	const [
		mutate, { loading: mutating }
	] = useMutation<TraitUpdateMutationMutation>(
		TraitUpdateMutation,
		{
			update: (cache) => {
				cache.evict({ fieldName: 'traits' });
				cache.evict({ id: `Trait:${traitId}` });
			}
		}
	);

	const trait: TraitQueryQuery['trait'] = removeTypeName(data?.trait);
	const items = trait?.rules || [];

	const goToAddScreen = useCallback(() => {
		push('TraitRuleAdd', { traitId });
	}, [traitId, push]);

	const goToEditScreen = useCallback((index: number) => () => {
		push('TraitRuleEdit', { traitId, index });
	}, [traitId, push]);

	const removeItem = useCallback((index: number) => () => {
		const newItems = [...(trait?.rules || [])];
		newItems.splice(index, 1);

		mutate({
			variables: {
				id: traitId,
				dto: {
					...trait,
					rules: newItems
				}
			}
		});
	}, [traitId, trait]);

	return (
		<View>
			<StatefulComponent
				loading={loading}
				error={error}
				data={items}
				delay={0}
				emptyComponent={TraitRuleEmptyState}
			>
				{
					items.map((definition, index) => (
						<>
							<TraitProfileStateItem
								definition={definition}
								disabled={mutating}
								onEdit={goToEditScreen(index)}
								onRemove={removeItem(index)}
							/>
							{index < items.length - 1 && <Divider />}
						</>
					))
				}
			</StatefulComponent>
			<Divider />
			<Button
				title="Add Rule"
				variant="text"
				icon={{ type: 'icon', name: 'plus' }}
				onPress={goToAddScreen}
			/>
		</View>
	);
};

TraitRuleList.defaultProps = {};
TraitRuleList.displayName = 'TraitRuleList';
