import { removeTypeName, Trait, TraitInput, TraitQueryQuery } from '@mevris/client-graphql';

export function getTraitInputDto(inputDto: Trait | TraitInput | TraitQueryQuery['trait']): TraitInput {
	const dto = removeTypeName(inputDto);

	const input: TraitInput = {
		attributes: dto?.attributes,
		commands: dto?.commands,
		description: dto?.description,
		id: dto?.id,
		key: dto?.key,
		measurements: dto?.measurements,
		name: dto?.name,
		parentId: dto?.parentId,
		reports: dto?.reports,
		rules: dto?.rules,
		scope: dto?.scope,
		states: dto?.states,
		status: dto?.status,
		ui: dto?.ui,
	};

	// Remove undefined properties
	Object.keys(input).forEach((key) => {
		if ((input as any)[key] === undefined) {
			delete (input as any)[key];
		}
	});

	return input;
}
