import { Body2, Subtitle1, View } from '@bluebase/components';
import { useStyles, useTheme } from '@bluebase/core';
import Editor, { BeforeMount, Monaco, OnMount, } from '@monaco-editor/react';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { TextStyle, ViewStyle } from 'react-native';

export interface JsonInputFieldStyles {
	root: ViewStyle;
	titleWrapper: ViewStyle;
	label: TextStyle;
	helperText: TextStyle;
	fieldWrapper: ViewStyle;
	errorWrapper: ViewStyle;
	error: TextStyle;
}

export interface JsonInputFieldProps {
	type: 'json',
	name: string,
	label: string;
	required?: boolean;
	helperText?: string;
	schema?: any;
	style?: ViewStyle;
	styles?: Partial<JsonInputFieldStyles>;
}

export const JsonInputField = (props: JsonInputFieldProps) => {

	const { name, style, label, helperText, required, schema } = props;

	const { theme } = useTheme();

	const [, { value, error }, helpers] = useField(props as any);
	const { setValue: setFormValue, setError } = helpers;

	const styles = useStyles<JsonInputFieldStyles>('JsonInputField', props, {
		root: {
			paddingVertical: theme.spacing.unit,
		},

		titleWrapper: {
			paddingHorizontal: theme.spacing.unit * 2,
			paddingVertical: theme.spacing.unit,
		},

		label: {
			color: error ? theme.palette.error.main : theme.palette.text.secondary,
		},

		helperText: {
			...theme.typography.caption,
			color: theme.palette.text.secondary,
			paddingTop: theme.spacing.unit / 2,
		},

		fieldWrapper: {
			marginVertical: theme.spacing.unit,
		},

		errorWrapper: {
			paddingHorizontal: theme.spacing.unit * 2,
			paddingVertical: theme.spacing.unit,
		},

		error: {
			color: theme.palette.error.main,
		},
	});

	const getObject = useCallback((value: string | undefined) => {
		if (!value) {
			return undefined;
		}

		try {
			const result = JSON.parse(value);
			return result;
		} catch (error) {
			return undefined;
		}
	}, []);

	const beforeMount: BeforeMount = useCallback((monaco: Monaco) => {
		if (schema) {
			monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
				enableSchemaRequest: true,
				validate: true,
				schemas: [{
					fileMatch: ['*'],
					schema
				}]
			});
		}
	}, [schema]);

	const onMount: OnMount = useCallback((editor, monaco) => {
		// editor.onDidFocusEditorWidget(() => {
		// 	console.log('Focus event triggerd !');
		// });

		editor.onDidBlurEditorWidget(() => {
			const markers = monaco.editor.getModelMarkers({ owner: 'json' });
			if (markers.length > 0) {
				setError(markers[0].message);
			}
			else {
				setError(undefined);
			}

			setFormValue(getObject(editor.getValue()));
		});
	}, []);

	return (
		<View style={[styles.root, style]} key={name}>
			<View style={styles.titleWrapper}>
				<Subtitle1 style={styles.label}>{label}{required ? '*' : ''}</Subtitle1>
				{helperText ? <Body2 style={styles.helperText}>{helperText}</Body2> : null}
			</View>
			<View style={styles.fieldWrapper}>
				<Editor
					height="30vh"
					defaultLanguage="json"
					defaultValue={JSON.stringify(value, null, 4)}
					// onValidate={handleEditorValidation}
					// onChange={onChange}
					theme={theme.mode === 'dark' ? 'vs-dark' : 'light'}
					beforeMount={beforeMount}
					onMount={onMount}
				/>
			</View>
			{
				error ? (
					<View style={styles.errorWrapper}>
						<Body2 style={styles.error}>{error}</Body2>
					</View>
				) : null
			}
		</View>

	);
};

JsonInputField.defaultProps = {
};

JsonInputField.displayName = 'JsonInputField';
