import { DataType } from '@mevris/universal-data-schema';

export function getFieldType(type?: DataType) {
	switch (type) {
		case 'STRING':
			return 'text';
		case 'NUMBER':
			return 'number';
		case 'BOOLEAN':
			return 'picker';
		case 'DATE':
			return 'date';
		default:
			return 'text';
	}
}
