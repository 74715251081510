import { FormTextInputProps } from '@bluebase/plugin-json-schema-components';
import { useField, useFormikContext } from 'formik';
import toCamelCase from 'lodash.camelcase';
import kebabcase from 'lodash.kebabcase';

function camelCase(str: string) {
	return str
		.split('.')
		.map(toCamelCase)
		.filter(s => s)
		.join('.');
}

export interface AutoKeyGeneratorProps extends FormTextInputProps {
	type: 'auto-key-generator',
	casing: 'kebabcase' | 'camelcase',
	keyProperty: string,
}

export const AutoKeyGenerator = ({ keyProperty, casing }: AutoKeyGeneratorProps) => {
	const [, meta] = useField('name');
	const [,, keyField] = useField(keyProperty);

	const { setFieldValue, values } = useFormikContext<{name?: string, key?: string}>();
	const { name } = values;
	const key = (values as any)[keyProperty];

	if (meta.touched && name !== undefined && (key === undefined || key === '')) {
		if (casing === 'camelcase') {
			setFieldValue(keyProperty, camelCase(name));
		}
		else if (casing === 'kebabcase') {
			setFieldValue(keyProperty, kebabcase(name));
		}

		keyField.setTouched(true);
	}

	return null;
};

AutoKeyGenerator.defaultProps = {
	keyProperty: 'key',
	casing: 'camelcase'
};
AutoKeyGenerator.displayName = 'AutoKeyGenerator';
