import { getComponentWithFallback } from '@bluebase/components';
import { PlaceholderListItemProps } from '@bluebase/plugin-rn-placeholder';
import { SettingsPageItemProps } from '@bluebase/plugin-settings-app';
import {
	DangerSettingActionProps,
	DeleteIconButtonProps,
	OneColumnDetailLayoutProps,
	TwoColumnDetailLayoutProps,
	WidgetProps,
} from '@blueeast/client-plugin-ui';
import {
	ControlUIProps,
	DataItemViewProps,
	DeviceProviderProps,
	SensorUIProps,
	SettingsUIProps,
} from '@mevris/client-plugin-device-controllers';
import {
	BrandAvatarSettingProps,
	BrandListProps,
	BrandPickerProps,
	DeviceTypeAvatarSettingProps,
	DeviceTypeListProps,
	DeviceTypePickerProps,
	EntityPickerProps,
	ProductAvatarSettingProps,
	ProductListProps,
	SchemaAvatarSettingProps,
	SchemaListProps,
	SchemaPickerProps,
	TraitAvatarSettingProps,
	TraitListProps,
	TraitPickerProps,
} from '@mevris/client-plugin-ui';

export const BrandAvatarSetting = getComponentWithFallback<BrandAvatarSettingProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'BrandAvatarSetting',
})('BrandAvatarSetting');
BrandAvatarSetting.displayName = 'BrandAvatarSetting';
export { BrandAvatarSettingProps };

export const BrandList = getComponentWithFallback<BrandListProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'BrandList',
})('BrandList');
BrandList.displayName = 'BrandList';
export { BrandListProps };

export const BrandPicker = getComponentWithFallback<BrandPickerProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'BrandPicker',
})('BrandPicker');
BrandPicker.displayName = 'BrandPicker';
export { BrandPickerProps };

export const ControlUI = getComponentWithFallback<ControlUIProps>({
	moduleName: '@mevris/client-plugin-device-controllers',
	name: 'ControlUI',
})('ControlUI');
ControlUI.displayName = 'ControlUI';
export { ControlUIProps };

export const DataItemView = getComponentWithFallback<DataItemViewProps>({
	moduleName: '@mevris/client-plugin-device-controllers',
	name: 'DataItemView',
})('DataItemView');
DataItemView.displayName = 'DataItemView';
export { DataItemViewProps };

export const DangerSettingAction = getComponentWithFallback<DangerSettingActionProps>({
	moduleName: '@blueeast/client-plugin-ui',
	name: 'DangerSettingAction',
})('DangerSettingAction');
DangerSettingAction.displayName = 'DangerSettingAction';

export const DeleteIconButton = getComponentWithFallback<DeleteIconButtonProps>({
	moduleName: '@blueeast/client-plugin-ui',
	name: 'DeleteIconButton',
})('DeleteIconButton');
DeleteIconButton.displayName = 'DeleteIconButton';

export const DeviceProvider = getComponentWithFallback<DeviceProviderProps>({
	moduleName: '@mevris/client-plugin-device-controllers',
	name: 'DeviceProvider',
})('DeviceProvider');
DeviceProvider.displayName = 'DeviceProvider';
export { DeviceProviderProps };

export const DeviceTypeAvatarSetting = getComponentWithFallback<DeviceTypeAvatarSettingProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'DeviceTypeAvatarSetting',
})('DeviceTypeAvatarSetting');
DeviceTypeAvatarSetting.displayName = 'DeviceTypeAvatarSetting';
export { DeviceTypeAvatarSettingProps };

export const DeviceTypeList = getComponentWithFallback<DeviceTypeListProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'DeviceTypeList',
})('DeviceTypeList');
DeviceTypeList.displayName = 'DeviceTypeList';
export { DeviceTypeListProps };

export const DeviceTypePicker = getComponentWithFallback<DeviceTypePickerProps>({
	moduleName: '@mevris/client-plugin-device-controllers',
	name: 'DeviceTypePicker',
})('DeviceTypePicker');
DeviceTypePicker.displayName = 'DeviceTypePicker';
export { DeviceTypePickerProps };

export const EntityPicker = getComponentWithFallback<EntityPickerProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'EntityPicker',
})('EntityPicker');
EntityPicker.displayName = 'EntityPicker';
export { EntityPickerProps };

export const OneColumnDetailLayout = getComponentWithFallback<OneColumnDetailLayoutProps>({
	moduleName: '@blueeast/client-plugin-ui',
	name: 'OneColumnDetailLayout',
})('OneColumnDetailLayout');
OneColumnDetailLayout.displayName = 'OneColumnDetailLayout';

export const Panel = getComponentWithFallback<SettingsPageItemProps>({
	moduleName: '@bluebase/plugin-settings-app',
	name: 'Panel',
})('SettingsPageItem');
Panel.displayName = 'Panel';
export { SettingsPageItemProps as PanelProps };

export const PlaceholderListItem = getComponentWithFallback<PlaceholderListItemProps>({
	moduleName: '@bluebase/plugin-rn-placeholder',
	name: 'PlaceholderListItem',
})('PlaceholderListItem');
PlaceholderListItem.displayName = 'PlaceholderListItem';
export { PlaceholderListItemProps };

export const ProductAvatarSetting = getComponentWithFallback<ProductAvatarSettingProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'ProductAvatarSetting',
})('ProductAvatarSetting');
ProductAvatarSetting.displayName = 'ProductAvatarSetting';
export { ProductAvatarSettingProps };

export const ProductList = getComponentWithFallback<ProductListProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'ProductList',
})('ProductList');
ProductList.displayName = 'ProductList';
export { ProductListProps };

export const SchemaAvatarSetting = getComponentWithFallback<SchemaAvatarSettingProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'SchemaAvatarSetting',
})('SchemaAvatarSetting');
SchemaAvatarSetting.displayName = 'SchemaAvatarSetting';
export { SchemaAvatarSettingProps };

export const SchemaList = getComponentWithFallback<SchemaListProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'SchemaList',
})('SchemaList');
SchemaList.displayName = 'SchemaList';
export { SchemaListProps };

export const SchemaPicker = getComponentWithFallback<SchemaPickerProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'SchemaPicker',
})('SchemaPicker');
SchemaPicker.displayName = 'SchemaPicker';
export { SchemaPickerProps };

export const SensorUI = getComponentWithFallback<SensorUIProps>({
	moduleName: '@mevris/client-plugin-device-controllers',
	name: 'SensorUI',
})('SensorUI');
SensorUI.displayName = 'SensorUI';
export { SensorUIProps };

export const SettingsUI = getComponentWithFallback<SettingsUIProps>({
	moduleName: '@mevris/client-plugin-device-controllers',
	name: 'SettingsUI',
})('SettingsUI');
SettingsUI.displayName = 'SettingsUI';
export { SettingsUIProps };

export const TraitAvatarSetting = getComponentWithFallback<TraitAvatarSettingProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'TraitAvatarSetting',
})('TraitAvatarSetting');
TraitAvatarSetting.displayName = 'TraitAvatarSetting';
export { TraitAvatarSettingProps };

export const TraitList = getComponentWithFallback<TraitListProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'TraitList',
})('TraitList');
TraitList.displayName = 'TraitList';
export { TraitListProps };

export const TraitPicker = getComponentWithFallback<TraitPickerProps>({
	moduleName: '@mevris/client-plugin-ui',
	name: 'TraitPicker',
})('TraitPicker');
TraitPicker.displayName = 'TraitPicker';
export { TraitPickerProps };

export const TwoColumnDetailLayout = getComponentWithFallback<TwoColumnDetailLayoutProps>({
	moduleName: '@blueeast/client-plugin-ui',
	name: 'TwoColumnDetailLayout',
})('TwoColumnDetailLayout');
TwoColumnDetailLayout.displayName = 'TwoColumnDetailLayout';

export const Widget = getComponentWithFallback<WidgetProps>({
	moduleName: '@blueeast/client-plugin-ui',
	name: 'Widget',
})('Widget');
Widget.displayName = 'Widget';
export { WidgetProps };
