import { useComponent } from '@bluebase/core';
import { JsonFormProps } from '@bluebase/plugin-json-schema-components';
import { DataType, SyncLinkMeasurementDirection, SyncLinkStateDirection, TraitSyncState } from '@mevris/client-graphql';
import React from 'react';

import { getOperationsList } from './getOperationsList';

export interface StateSyncSettingsFormProps {
	sync?: Partial<TraitSyncState>,
	dataType: DataType,
	property: 'states' | 'measurements',
	onChange?: (sync: Partial<TraitSyncState>) => void,
}

export const StateSyncSettingsForm = (props: StateSyncSettingsFormProps) => {
	const { sync, dataType, property, onChange } = props;
	const JsonForm = useComponent<JsonFormProps<Partial<TraitSyncState>>>('JsonForm');

	let stateFields: any[] = [];

	if (property === 'states') {
		stateFields = [
			{
				type: 'component',
				schema: { component: 'Divider' }
			},
			{
				label: 'Direction',
				name: 'direction',
				type: 'picker',
				helperText: 'The direction of flow of data.',

				items: [
					{
						label: 'Two Way',
						value: SyncLinkStateDirection.TwoWay,
					},
					{
						label: 'Input',
						value: SyncLinkStateDirection.Input,
					},
					{
						label: 'Output',
						value: SyncLinkStateDirection.Output,
					}
				],
			},
			{
				type: 'component',
				schema: { component: 'Divider' },

				displayOptions: {
					show: {
						direction: [SyncLinkStateDirection.Input]
					}
				}
			},
			{
				label: 'Operation',
				name: 'operation',
				type: 'picker',
				helperText: 'Aggregation function to perform on all states before applying it.',
				items: getOperationsList(dataType),

				displayOptions: {
					show: {
						direction: [SyncLinkStateDirection.Input]
					}
				}
			}
		];
	}

	if (property === 'measurements') {
		stateFields = [
			{
				type: 'component',
				schema: { component: 'Divider' }
			},
			{
				label: 'Direction',
				name: 'direction',
				type: 'picker',
				helperText: 'The direction of flow of data.',

				items: [
					{
						label: 'Input',
						value: SyncLinkMeasurementDirection.Input,
					},
					{
						label: 'Output',
						value: SyncLinkMeasurementDirection.Output,
					}
				],
			},
		];
	}

	return (
		<JsonForm
			key={dataType}
			schema={{

				initialValues: {
					...sync,
				},

				fields: [
					{
						label: 'Auto',
						name: 'auto',
						type: 'picker',
						// eslint-disable-next-line max-len
						helperText: 'Allows adding child entities as data sources automatically, whenever new child entity is added.',

						items: [
							{
								label: 'Yes',
								value: true,
							},
							{
								label: 'No',
								value: false,
							},
						],
					},
					...stateFields as any,
				],

				onSubmit: () => {},

				onChange: (ctx) => {
					const values = ctx.values;

					if (onChange) {
						onChange(values);
					}
				},
			}}
		/>
	);
};

StateSyncSettingsForm.defaultProps = {
};

StateSyncSettingsForm.displayName = 'StateSyncSettingsForm';
