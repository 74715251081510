import { Theme, useStyles } from '@bluebase/core';
import { FormPickerInput, FormTextInput } from '@bluebase/plugin-json-schema-components';
import { useField } from 'formik';
import React from 'react';
import { TextStyle, View, ViewStyle } from 'react-native';

import { JsonInputField } from '../../form-fields';

export interface NestedFieldStyles {
	root: ViewStyle;
	titleWrapper: ViewStyle;
	label: TextStyle;
	helperText: TextStyle;
	fieldWrapper: ViewStyle;
}

export interface NestedFieldProps {
	type: 'nested',
	name: string,
	style?: ViewStyle;
	styles?: Partial<NestedFieldStyles>;
}

const defaultStyles = (theme: Theme): NestedFieldStyles => ({
	root: {
		paddingVertical: theme.spacing.unit,
	},

	titleWrapper: {
		paddingHorizontal: theme.spacing.unit * 2,
		paddingVertical: theme.spacing.unit,
	},

	label: {
		color: theme.palette.text.secondary,
	},

	helperText: {
		...theme.typography.caption,
		color: theme.palette.text.secondary,
		paddingTop: theme.spacing.unit / 2,
	},

	fieldWrapper: {
		marginBottom: theme.spacing.unit,
		marginHorizontal: theme.spacing.unit * 2,
	},
});

export const NestedField = (props: NestedFieldProps) => {

	const { name, style, } = props;
	const styles = useStyles<NestedFieldStyles>('NestedField', props, defaultStyles);

	const [field] = useField({ name: `${name}.type` });

	const type = field.value;

	return (
		<View style={[styles.root, style]}>
			<FormPickerInput
				name={`${name}.type`}
				label="Type"
				type="picker" items={[
					{
						label: 'Regex',
						value: 'regex',
					},
					{
						label: 'Template',
						value: 'template',
					},
					{
						label: 'Rule',
						value: 'rule',
					},
				]}
			/>

			{
				type !== 'rule' && (
					<FormTextInput name={`${name}.value`} type="text" label="Value" />
				)
			}

			{
				type === 'rule' && (
					<JsonInputField name={`${name}.value`} type="json" label="Value" />
				)
			}
		</View>
	);
};

NestedField.displayName = 'NestedField';
NestedField.defaultProps = {};
