import { useMutation, useQuery } from '@apollo/client';
import {
	Button,
	ComponentState,
	Dialog,
	Divider,
	StatefulComponent,
	View,
} from '@bluebase/components';
import { useNavigation } from '@bluebase/core';
import {
	DataItemDefinition,
	TraitCommand,
	TraitQuery,
	TraitQueryQuery,
	TraitUpdateMutation,
	TraitUpdateMutationMutation
} from '@mevris/client-graphql';
import React, { useCallback, useState } from 'react';

import { getTraitInputDto } from '../../helpers';
import DataSchemaConsole from '../DataSchemaConsole';
import { TraitCommandEmptyState } from './TraitCommandEmptyState';
import { TraitCommandListItem } from './TraitCommandListItem';

export interface TraitCommandListProps {
	traitId: string;
}

export const TraitCommandList = (props: TraitCommandListProps) => {
	const { traitId } = props;
	const { push } = useNavigation();

	// Schema Simulation
	const [selectedItem, setSelectedItem] = useState<DataItemDefinition>();
	const [dialogVisible, setDialogVisible] = useState(false);

	const toggleDialog = useCallback(() => {
		setDialogVisible(!dialogVisible);
	}, [dialogVisible]);

	const simulateSchema = useCallback((item: DataItemDefinition) => () => {
		setSelectedItem(item);
		setDialogVisible(true);
	}, []);

	// Simulation end

	if (!traitId) {
		return <ComponentState description="No Trait ID given" />;
	}

	const { loading, error, data } = useQuery<TraitQueryQuery>(
		TraitQuery,
		{ variables: { id: traitId } }
	);

	const [
		mutate, { loading: mutating }
	] = useMutation<TraitUpdateMutationMutation>(
		TraitUpdateMutation,
		{
			update: (cache) => {
				cache.evict({ fieldName: 'traits' });
				cache.evict({ id: `Trait:${traitId}` });
			}
		}
	);

	const trait = data?.trait ? getTraitInputDto(data?.trait) : {};
	const items = data?.trait.commands || [];

	const goToAddScreen = useCallback(() => {
		push('TraitCommandAdd', { traitId });
	}, [traitId, push]);

	const goToEditScreen = useCallback((index: number) => () => {
		push('TraitCommandEdit', { traitId, commandIndex: index });
	}, [traitId, push]);

	const goToAddArgScreen = useCallback((commandIndex: number) => () => {
		push('TraitCommandArgumentAdd', { traitId, commandIndex });
	}, [traitId, push]);

	const goToEditArgScreen = useCallback((commandIndex: number) => (argIndex: number) => () => {
		push('TraitCommandArgumentEdit', { traitId, commandIndex, argIndex });
	}, [traitId, push]);

	const removeItem = useCallback((index: number) => () => {
		const newItems = trait?.commands ? [...trait.commands] : [];
		newItems.splice(index, 1);

		mutate({
			variables: {
				id: traitId,
				dto: {
					...trait,
					commands: newItems
				}
			}
		});
	}, [traitId, trait]);

	const removeArgument = useCallback((commandIndex: number) => (argIndex: number) => () => {
		const commands = trait?.commands ? [...trait.commands] : [];
		commands[commandIndex].arguments?.splice(argIndex, 1);

		mutate({
			variables: {
				id: traitId,
				dto: {
					...trait,
					commands
				}
			}
		});
	}, [traitId, trait]);

	return (
		<View>
			<StatefulComponent
				loading={loading}
				error={error}
				data={items}
				delay={0}
				emptyComponent={TraitCommandEmptyState}
			>
				{
					items.map((definition, index) => (
						<>
							<TraitCommandListItem
								command={definition as TraitCommand}
								disabled={mutating}
								onEdit={goToEditScreen(index)}
								onRemove={removeItem(index)}
								onAddArgument={goToAddArgScreen(index)}
								onEditArgument={goToEditArgScreen(index)}
								onRemoveArgument={removeArgument(index)}
								onSimulate={simulateSchema}
							/>
							{index < items.length - 1 && <Divider />}
						</>
					))
				}
			</StatefulComponent>
			<Divider />
			<Button
				title="Add Command"
				variant="text"
				icon={{ type: 'icon', name: 'plus' }}
				onPress={goToAddScreen}
			/>
			<Dialog visible={dialogVisible} dismissable onDismiss={toggleDialog}>
				<Dialog.Title>{selectedItem?.name}</Dialog.Title>
				<Divider />
				<DataSchemaConsole schema={selectedItem?.schema} />
			</Dialog>
		</View>
	);
};

TraitCommandList.defaultProps = {};
TraitCommandList.displayName = 'TraitCommandList';
