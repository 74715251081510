import { useQuery } from '@apollo/client';
import { StatefulComponent } from '@bluebase/components';
import { useNavigation } from '@bluebase/core';
import { TraitDeleteMutation, TraitQuery, TraitQueryQuery } from '@mevris/client-graphql';
import * as React from 'react';

import { DangerSettingAction } from '../../imports';
import { LoadingState } from './LoadingState';

export interface TraitDeleteSettingProps {
	traitId: string;
}

export const TraitDeleteSetting = ({ traitId }: TraitDeleteSettingProps) => {
	const { navigate } = useNavigation();
	const { data, error, loading } = useQuery<TraitQueryQuery>(TraitQuery, { variables: { id: traitId } });

	const onSuccess = React.useCallback(() => {
		navigate('TraitList');
	}, [navigate]);

	return (
		<StatefulComponent data={data?.trait.name} loading={loading} error={error} loadingComponent={LoadingState}>
			<DangerSettingAction
				code={data?.trait.name!}
				onSuccess={onSuccess}
				mutation={{
					mutation: TraitDeleteMutation,
					variables: {
						data: {
							id: traitId,
						},
					} as any,
					update: (cache) => {
						cache.evict({ fieldName: 'traits' });
						cache.evict({ id: `Trait:${traitId}` });
					}
				}}
				schema={{ initialValues: { id: traitId, code: '' } }}
				iconProps={{ name: 'delete' }}
				title="Delete"
				description="Delete this trait permanently. This action is irreversible."
				formTitle="Delete Trait"
				formButtonTitle="Delete this trait"
				formDescription="Type the name of the trait to confirm deletion."
				formTextFieldLabel="Trait Name"
			/>
		</StatefulComponent>
	);
};

TraitDeleteSetting.displayName = 'TraitDeleteSetting';
