import { ComponentState } from '@bluebase/components';
import { useTheme } from '@bluebase/core';
import React from 'react';

export const TraitRuleEmptyState = () => {
	const { theme } = useTheme();

	return (
		<ComponentState
			styles={{ description: { color: theme.palette.text.disabled, fontStyle: 'italic' } }}
			description="No data here, try adding new items."
		/>
	);
};

TraitRuleEmptyState.defaultProps = {};
TraitRuleEmptyState.displayName = 'TraitRuleEmptyState';
