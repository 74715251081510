import { useQuery } from '@apollo/client';
import { StatefulComponent } from '@bluebase/components';
import { useNavigation } from '@bluebase/core';
import { SchemaDeleteMutation, SchemaQuery, SchemaQueryQuery } from '@mevris/client-graphql';
import * as React from 'react';

import { DangerSettingAction } from '../../imports';
import { LoadingState } from './LoadingState';

export interface SchemaDeleteSettingProps {
	schemaId: string;
}

export const SchemaDeleteSetting = ({ schemaId }: SchemaDeleteSettingProps) => {
	const { navigate } = useNavigation();
	const { data, error, loading } = useQuery<SchemaQueryQuery>(SchemaQuery, { variables: { id: schemaId } });

	const onSuccess = React.useCallback(() => {
		navigate('SchemaList');
	}, [navigate]);

	return (
		<StatefulComponent data={data?.schema.name} loading={loading} error={error} loadingComponent={LoadingState}>
			<DangerSettingAction
				code={data?.schema.name!}
				onSuccess={onSuccess}
				mutation={{
					mutation: SchemaDeleteMutation,
					variables: {
						data: {
							id: schemaId,
						},
					} as any,
					update: (cache) => {
						cache.evict({ fieldName: 'schemas' });
						cache.evict({ id: `Schema:${schemaId}` });
					}
				}}
				schema={{ initialValues: { id: schemaId, code: '' } }}
				iconProps={{ name: 'delete' }}
				title="Delete"
				description="Delete this schema permanently. This action is irreversible."
				formTitle="Delete Schema"
				formButtonTitle="Delete this schema"
				formDescription="Type the name of the schema to confirm deletion."
				formTextFieldLabel="Schema Name"
			/>
		</StatefulComponent>
	);
};

SchemaDeleteSetting.displayName = 'SchemaDeleteSetting';
