import { DataItemDefinition } from '@mevris/client-graphql';

export function toFormValues(definition?: DataItemDefinition) {
	if (!definition) {
		return {};
	}

	const { schema = {}, ...rest } = definition;

	const result = {
		...schema,
		availableUnits: schema.availableUnits?.map((unit: string) => ({ unit })) || [],
	} as any;

	if (result.validators && result.validators.length > 0) {
		result.validators = result.validators.map((x: any) => ({ item: x }));
	}

	if (result.transformers && result.transformers.length > 0) {
		result.transformers = result.transformers.map((x: any) => ({ item: x }));
	}

	if (result.converters && result.converters.length > 0) {
		result.converters = result.converters.map((x: any) => ({ item: x }));
	}

	return {
		...rest,
		schema: result,
	};
}
