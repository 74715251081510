import {
	Body1,
	Body2,
	ComponentState,
	Divider,
	Icon,
	List,
	Text,
	View
} from '@bluebase/components';
import { useComponent, useTheme } from '@bluebase/core';
import { JsonFormProps } from '@bluebase/plugin-json-schema-components';
import {
	DataSchema,
	DataType,
	DataTypeValue,
	print,
	resolveDataSchema,
	transform,
	validate
} from '@mevris/universal-data-schema';
import React, { useState } from 'react';

import { DataItemView } from '../../imports';
import { getFieldType } from './getFieldType';

export interface DataSchemaConsoleProps {
	schema?: DataSchema,
	onChange?: (schema: DataSchema) => void,
	onSubmit?: (schema: DataSchema) => void,
}

export const DataSchemaConsole = (props: DataSchemaConsoleProps) => {
	const { schema } = props;
	const { theme } = useTheme();
	const JsonForm = useComponent<JsonFormProps<any>>('JsonForm');

	if (!schema) {
		return (
			<ComponentState
				styles={{ description: { color: theme.palette.text.disabled, fontStyle: 'italic' } }}
				description="No schema to test. Select a data type, or extend an existing schema."
			/>
		);
	}

	const resolvedSchema = resolveDataSchema(schema);

	if (!resolvedSchema.dataType) {
		return (
			<ComponentState
				styles={{ description: { color: theme.palette.text.disabled } }}
				description="The data type is not valid. Select a data type, or extend an existing schema."
			/>
		);
	}

	const [dataType, setDataType] = useState<DataType | undefined>(resolvedSchema.dataType || 'STRING');
	const [value, setValue] = useState<DataTypeValue | undefined>(undefined);
	const [values, setValues] = useState<DataTypeValue[] | undefined>(undefined);

	const array = resolvedSchema.array || false;
	const input = array ? values : value;

	let isValid = true;
	let validateMessage = 'Ok';

	try {
		isValid = validate(transform(input, schema) as any, schema);

		if (!isValid) {
			validateMessage = 'Error';
		}
	} catch (error: any) {
		isValid = false;
		validateMessage = error.message;
	}

	return (
		<React.Fragment>
			<List.Subheader>Input</List.Subheader>
			<JsonForm
				key={`array-${String(!!schema.array)}`}
				schema={{
					fields: [
						{
							label: 'Data Type',
							name: 'dataType',
							type: 'picker',

							items: [
								{
									label: 'Boolean',
									value: 'BOOLEAN',
								},
								{
									label: 'Date',
									value: 'DATE',
								},
								{
									label: 'Number',
									value: 'NUMBER',
								},
								{
									label: 'String',
									value: 'STRING',
								},
							],
						},

						{
							label: 'Value',
							name: 'value',
							type: getFieldType(dataType),

							displayOptions: {
								show: {
									array: [false, undefined],
								}
							},

							items: dataType === 'BOOLEAN' ? [
								{
									label: '',
									value: undefined,
								},
								{
									label: 'True',
									value: true,
								},
								{
									label: 'False',
									value: false,
								},
							] : [],
						},

						{
							name: 'values',
							type: 'array',
							label: 'Values',
							addButtonLabel: 'Add Value',

							displayOptions: {
								show: {
									array: [true],
								}
							},

							fields: [
								{
									label: 'Value',
									name: 'value',
									type: getFieldType(dataType),
									required: true,

									items: dataType === 'BOOLEAN' ? [
										{
											label: 'True',
											value: true,
										},
										{
											label: 'False',
											value: false,
										},
									] : [],
								},
							],
						},
					],

					initialValues: {
						...resolvedSchema,
						dataType,
						array,
					},

					onSubmit: () => {},

					onChange: (ctx) => {
						const values = ctx.values;
						const dataType = values.dataType;

						if (array) {
							setValues(
								ctx.values.values.map((v: any) => castValue(v.value, dataType))
							);
						}
						else {
							setValue(castValue(values.value, dataType));
						}

						setDataType((values as any).dataType);
					},
				}}
			/>
			<Divider />
			<View>
				<List.Subheader>Output</List.Subheader>
				<DataItemView
					title={
						<Text style={{ display: 'flex', flexDirection: 'column', color: theme.palette.text.secondary }}>
							<Body1 style={{ color: theme.palette.text.secondary }}>Transform</Body1>
							<Body2 style={{ color: theme.palette.text.secondary }}>
								{typeof input}
								<Icon name="chevron-right" size={14} color={theme.palette.text.secondary} />
								{typeof transform(input, schema)}
							</Body2>
						</Text>
					}
					value={
						input !== undefined
							?
							(
								array
									? input?.toString()
									: String(transform(input, schema))
							)
							: undefined
					}
				/>
				<DataItemView
					title="Validate"
					value={validateMessage}
					styles={{
						value: {
							color: isValid
								? theme.palette.success.main
								: theme.palette.error.main,
						}
					}}
				/>
				<DataItemView
					title="Convert & Print"
					value={
						isValid && input !== undefined
							? print(input, schema)
							: undefined
					}
				/>
			</View>
		</React.Fragment>

	);
};

DataSchemaConsole.defaultProps = {
};

DataSchemaConsole.displayName = 'DataSchemaConsole';

function castValue(input: any, dataType: string) {

	let value = input;

	if (dataType === 'DATE') {
		value = new Date(value);
	}
	else if (dataType === 'NUMBER') {
		value = Number(value);
	}

	return value;
}
