import { Schema, SchemaInput } from '@mevris/client-graphql';

export function getSchemaInputDto(dto: Schema): SchemaInput {
	const input: SchemaInput = {
		attributes: dto.attributes,
		connector: dto.connector,
		description: dto.description,
		deviceTypeId: dto.deviceTypeId,
		featureSettings: dto.featureSettings,
		editableAttributes: dto.editableAttributes,
		syncableStates: dto.syncableStates,
		syncableMeasurements: dto.syncableMeasurements,
		entities: dto.entities,
		handle: dto.handle,
		id: dto.id,
		localDeviceController: dto.localDeviceController?.key ? dto.localDeviceController : null,
		mainCommand: dto.mainCommand,
		mainState: dto.mainState,
		name: dto.name,
		// parentId: dto.parentId,
		// parts: dto.parts,
		// schemaTraits: dto.schemaTraits,
		scope: dto.scope,
		status: dto.status,
	};

	// Remove undefined properties
	Object.keys(input).forEach((key) => {
		if ((input as any)[key] === undefined) {
			delete (input as any)[key];
		}
	});

	return input;
}
