import { PickerItemProps } from '@bluebase/components';
import { DataType } from '@mevris/client-graphql';

export function getOperationsList(dataType: DataType): PickerItemProps[] {
	if (dataType === DataType.Number) {
		// 'AVERAGE', 'COUNT', 'MAX', 'MIN', 'SUM'
		return [
			{
				label: '',
				value: undefined,
			},
			{
				label: 'Average',
				value: 'AVERAGE',
			},
			{
				label: 'Count',
				value: 'COUNT',
			},
			{
				label: 'Max',
				value: 'MAX',
			},
			{
				label: 'Min',
				value: 'MIN',
			},
			{
				label: 'Sum',
				value: 'SUM',
			},
		];
	}

	if (dataType === DataType.Boolean) {
		// 'AND', 'OR', 'XOR', 'NAND', 'NOR', 'XNOR'
		return [
			{
				label: '',
				value: undefined,
			},
			{
				label: 'AND',
				value: 'AND',
			},
			{
				label: 'OR',
				value: 'OR',
			},
			{
				label: 'XOR',
				value: 'XOR',
			},
			{
				label: 'NAND',
				value: 'NAND',
			},
			{
				label: 'NOR',
				value: 'NOR',
			},
			{
				label: 'XNOR',
				value: 'XNOR',
			},
		];
	}

	if (dataType === DataType.String) {
		// 'SHORTEST', 'LONGEST'
		return [
			{
				label: '',
				value: undefined,
			},
			{
				label: 'Shortest',
				value: 'SHORTEST',
			},
			{
				label: 'Longest',
				value: 'LONGEST',
			},
		];
	}

	if (dataType === DataType.Date) {
		// 'EARLIEST', 'LATEST'
		return [
			{
				label: '',
				value: undefined,
			},
			{
				label: 'Earliest',
				value: 'EARLIEST',
			},
			{
				label: 'Latest',
				value: 'LATEST',
			},
		];
	}

	return [];
}
