import { IconButton, List } from '@bluebase/components';
import { useTheme } from '@bluebase/core';
import { EmbeddedRuleInput } from '@mevris/client-graphql';
import React from 'react';

import { DeleteIconButton } from '../../imports';

export interface TraitProfileStateItemProps {
	definition: EmbeddedRuleInput;
	disabled?: boolean;
	onEdit: () => void;
	onRemove: () => void;
}

export const TraitProfileStateItem = (props: TraitProfileStateItemProps) => {
	const { definition, disabled, onEdit, onRemove } = props;
	const { theme } = useTheme();

	return (
		<List.Item
			title={definition.name}
			description={definition.description}
			disabled={disabled}
			right={
				<>
					<IconButton
						name="pencil"
						size={20}
						color={theme.palette.text.secondary}
						onPress={onEdit}
					/>
					<DeleteIconButton size={20} onDelete={onRemove} />
				</>
			}
		/>
	);
};

TraitProfileStateItem.defaultProps = {};
TraitProfileStateItem.displayName = 'TraitProfileStateItem';
