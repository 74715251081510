import {
	Body2,
	IconButton,
	List,
	Text,
	View
} from '@bluebase/components';
import { useTheme } from '@bluebase/core';
import { DataItemDefinition, TraitCommand } from '@mevris/client-graphql';
import React from 'react';
import { TextStyle } from 'react-native';

import { DeleteIconButton } from '../../imports';
import { TraitCommandArgumentListItem } from './TraitCommandArgumentListItem';

export interface TraitCommandListItemStyles {
	descriptionWrapper: TextStyle;
	description: TextStyle;
	badgeWrapper: TextStyle;
	badge: TextStyle;
	badgeGreen: TextStyle;
	badgeOrange: TextStyle;
}

export interface TraitCommandListItemProps {
	command: TraitCommand;
	disabled?: boolean;
	onEdit: () => void;
	onRemove: () => void;

	onAddArgument: () => void;
	onEditArgument: (index: number) => () => void;
	onRemoveArgument: (index: number) => () => void;
	onSimulate: (definition: DataItemDefinition) => () => void;
}

export const TraitCommandListItem = (props: TraitCommandListItemProps) => {
	const { command, disabled, onEdit, onRemove, onAddArgument, onEditArgument, onRemoveArgument, onSimulate } = props;
	const { theme } = useTheme();

	const styles: TraitCommandListItemStyles = {
		descriptionWrapper: {
			display: 'flex',
			flexDirection: 'column',
		},

		description: {
			color: theme.palette.text.secondary,
		},

		badgeWrapper: {
			paddingVertical: theme.spacing.unit / 2,
		},

		badge: {
			backgroundColor: theme.palette.success.light,
			color: theme.palette.success.contrastText,
			padding: theme.spacing.unit / 2,
			borderRadius: theme.shape.borderRadius,
			marginRight: theme.spacing.unit,
			fontSize: 10
		},

		badgeGreen: {
			backgroundColor: theme.palette.success.light,
			color: theme.palette.success.contrastText,
		},

		badgeOrange: {
			backgroundColor: theme.palette.warning.light,
			color: theme.palette.warning.contrastText,
		},
	};

	const args = command.arguments || [];

	return (
		<>
			<List.Item
				key={command.key}
				title={command.name}
				description={
					<Text style={styles.descriptionWrapper}>
						<Body2 style={styles.description}>{command.description}</Body2>
						<Text style={styles.badgeWrapper}>
							<Body2 style={[styles.badge, styles.badgeGreen]}>{command.key}</Body2>
							{
								// definition.type
								// 	? (<Body2 style={[styles.badge, styles.badgeOrange]}>{definition.type}</Body2>)
								// 	: null
							}
						</Text>
					</Text>
				}
				disabled={disabled}
				right={
					<>
						<IconButton
							name="layers-plus"
							size={20}
							color={theme.palette.text.secondary}
							onPress={onAddArgument}
						/>
						<IconButton
							name="pencil"
							size={20}
							color={theme.palette.text.secondary}
							onPress={onEdit}
						/>
						<DeleteIconButton size={20} onDelete={onRemove} />
					</>
				}
			/>

			<View style={{ marginLeft: theme.spacing.unit * 4 }}>
				{args.map((arg, index) => (
					<TraitCommandArgumentListItem
						key={arg.key}
						definition={arg}
						onSimulate={onSimulate(arg)}
						onEdit={onEditArgument(index)}
						onRemove={onRemoveArgument(index)}
					/>
				))}
			</View>
		</>
	);
};

TraitCommandListItem.defaultProps = {};
TraitCommandListItem.displayName = 'TraitCommandListItem';
