import { useComponent } from '@bluebase/core';
import { JsonGraphqlFormProps } from '@bluebase/plugin-json-graphql-components';
import { DataItemDefinition } from '@mevris/client-graphql';
import { DataType, Presets, resolveDataSchema } from '@mevris/universal-data-schema';
import React, { useCallback, useState } from 'react';

import { AutoKeyGenerator, JsonInputField } from '../../form-fields';
import { fromFormValues } from './fromFormValues';
import { getFieldType } from './getFieldType';
import { NestedField } from './NestedField';
import { toFormValues } from './toFormValues';

export interface DataItemDefinitionGraphqlFormProps extends Omit<JsonGraphqlFormProps<DataItemDefinition>, 'schema'> {
	onChange?: (definition: DataItemDefinition) => void,
}

export const DataItemDefinitionGraphqlForm = (props: DataItemDefinitionGraphqlFormProps) => {
	const { onChange } = props;
	const JsonGraphqlForm = useComponent<JsonGraphqlFormProps<DataItemDefinition>>('JsonGraphqlForm');

	const [dataType, setDataType] = useState<DataType | undefined>('STRING');

	const mapQueryDataToInitialValues = useCallback((data: DataItemDefinition) => {
		const definition = props.mapQueryDataToInitialValues
			? props.mapQueryDataToInitialValues(data)
			: data;

		if (!definition) {
			return;
		}

		const initialValues: any = toFormValues(definition);
		const resolvedSchema = resolveDataSchema(initialValues?.schema);

		setDataType(resolvedSchema.dataType);

		return {
			...definition,
			resolvedDataType: resolvedSchema.dataType,
		};
	}, []);

	const mapFormValuesToMutationVariables = useCallback((input: any) => {
		const { resolvedDataType, ...useful } = input;

		const values = fromFormValues(useful);

		const result = props.mapFormValuesToMutationVariables
			? props.mapFormValuesToMutationVariables(values)
			: values;

		return result;
	}, [props.mapFormValuesToMutationVariables]);

	return (
		<JsonGraphqlForm
			{...props}
			mapQueryDataToInitialValues={mapQueryDataToInitialValues as any}
			mapFormValuesToMutationVariables={mapFormValuesToMutationVariables}
			schema={{
				validateOnBlur: false,
				validateOnChange: false,

				fieldTypes: {
					json: [JsonInputField],
					nested: [NestedField],
					'auto-key-generator': [AutoKeyGenerator]
				},

				validate: (values: any) => {

					const errors: any = {};

					if (!values.schema?.dataType && !values.schema?.extends) {
						errors.form = ['The data type is not valid. Select a data type, or extend an existing schema.'];
					}

					return errors;
				},

				fields: [
					{
						label: 'Name',
						name: 'name',
						type: 'text',
						required: true,
					},
					{
						label: 'Key',
						name: 'key',
						type: 'text',
						required: true,
					},
					{
						type: 'auto-key-generator',
						name: 'auto-key-generator',
					},
					{
						label: 'Description',
						name: 'description',
						type: 'text',
						multiline: true,
        		numberOfLines: 4
					},
					{
						label: 'Extends',
						name: 'schema.extends',
						type: 'picker',

						displayOptions: {
							show: {
								'schema.dataType': [undefined],
							}
						},

						items: [
							{
								label: '',
								value: undefined,
							},
							...Object.keys(Presets).map(preset => ({
								label: preset,
								value: preset,
							}))
						],
					},
					{
						label: 'Data Type',
						name: 'schema.dataType',
						type: 'picker',

						displayOptions: {
							show: {
								'schema.extends': [undefined],
							}
						},

						items: [
							{
								label: '',
								value: undefined,
							},
							{
								label: 'Boolean',
								value: 'BOOLEAN',
							},
							{
								label: 'Date',
								value: 'DATE',
							},
							{
								label: 'Number',
								value: 'NUMBER',
							},
							{
								label: 'String',
								value: 'STRING',
							},
						],
					},

					{
						name: 'number-max-min',
						type: 'inline',
						displayOptions: {
							show: {
								'resolvedDataType': ['NUMBER'],
							}
						},

						fields: [
							{
								label: 'Minimum Value',
								name: 'schema.minimumValue',
								type: 'number',
							},
							{
								label: 'Maximum Value',
								name: 'schema.maximumValue',
								type: 'number',
							},
						],
					},
					{
						name: 'number-step-precision',
						type: 'inline',
						displayOptions: {
							show: {
								'resolvedDataType': ['NUMBER'],
							}
						},

						fields: [
							{
								label: 'Step',
								name: 'schema.step',
								type: 'number',
							},
							{
								label: 'Precision',
								name: 'schema.precision',
								type: 'number',
							},
						],
					},

					{
						name: 'defaults',
						type: 'inline',

						displayOptions: {
							hide: {
								'resolvedDataType': [undefined],
							}
						},

						fields: [
							{
								label: 'Default Value',
								name: 'schema.defaultValue',
								type: getFieldType(dataType),

								items: dataType === 'BOOLEAN' ? [
									{
										label: '',
										value: undefined,
									},
									{
										label: 'True',
										value: true,
									},
									{
										label: 'False',
										value: false,
									},
								] : [],
							},
							{
								label: 'Unit',
								name: 'schema.unit',
								type: 'text',
								displayOptions: {
									show: {
										'resolvedDataType': ['NUMBER'],
									}
								}
							},
						],
					},

					{
						name: 'availableUnits',
						type: 'array',
						label: 'Available Units',
						addButtonLabel: 'Add Unit',

						fields: [
							{
								label: 'Unit',
								name: 'unit',
								type: 'text',
							},
						],
						displayOptions: {
							show: {
								'resolvedDataType': ['NUMBER'],
							}
						}
					},

					{
						name: 'string-length-inline',
						type: 'inline',
						displayOptions: {
							show: {
								'resolvedDataType': ['STRING'],
							}
						},

						fields: [
							{
								label: 'Maximum Length',
								name: 'schema.maximumLength',
								type: 'number',
							},
							{
								label: 'Minimum Length',
								name: 'schema.minimumLength',
								type: 'number',
							},
						],
					},

					{
						name: 'schema.values',
						type: 'array',
						label: 'Values',
						addButtonLabel: 'Add Value',

						displayOptions: {
							hide: {
								'resolvedDataType': [undefined],
							}
						},

						fields: [
							{
								label: 'Value',
								name: 'value',
								type: getFieldType(dataType),
								required: true,

								items: dataType === 'BOOLEAN'
									? [
										{
											label: 'True',
											value: true,
										},
										{
											label: 'False',
											value: false,
										},
									]
									: [],
							},
							{
								label: 'Label',
								name: 'label',
								type: 'text',
								required: true,
							},
							{
								label: 'Description',
								name: 'description',
								type: 'text',
							},
						],
					},

					{
						label: 'Required',
						name: 'schema.required',
						type: 'checkbox',

						displayOptions: {
							hide: {
								'resolvedDataType': [undefined],
							}
						},
					},

					{
						name: 'array-inline',
						type: 'inline',

						displayOptions: {
							hide: {
								'resolvedDataType': [undefined],
							}
						},

						fields: [
							{
								label: 'Array',
								name: 'schema.array',
								type: 'checkbox',
							},
							{
								label: 'Minimum Items',
								name: 'schema.minimumItems',
								type: 'number',
								displayOptions: {
									show: {
										'schema.array': [true],
									}
								}
							},
							{
								label: 'Maximum Items',
								name: 'schema.maximumItems',
								type: 'number',
								displayOptions: {
									show: {
										'schema.array': [true],
									}
								}
							},
						],
					},

					{
						name: 'schema.validators',
						type: 'array',
						label: 'Validators',
						addButtonLabel: 'Add Validator',

						displayOptions: {
							hide: {
								'resolvedDataType': [undefined],
							}
						},

						fields: [
							{
								name: 'item',
								type: 'nested',
							},
						],
					},

					{
						name: 'schema.transformers',
						type: 'array',
						label: 'Transformers',
						addButtonLabel: 'Add Transformer',

						displayOptions: {
							hide: {
								'resolvedDataType': [undefined],
							}
						},

						fields: [
							{
								name: 'item',
								type: 'nested',
							},
						],
					},

					{
						name: 'schema.converters',
						type: 'array',
						label: 'Converters',
						addButtonLabel: 'Add Converter',

						displayOptions: {
							hide: {
								'resolvedDataType': [undefined],
							}
						},

						fields: [
							{
								name: 'item',
								type: 'nested',
							},
						],
					},

					{
						name: 'schema.printer',
						type: 'group',
						label: 'Printer',

						displayOptions: {
							hide: {
								'resolvedDataType': [undefined],
							}
						},

						fields: [
							{
								name: 'schema.printer',
								type: 'nested',
							},
						],
					},

					{
						name: 'status',
						type: 'status',
					},
					// {
					// 	type: 'component',
					// 	schema: { component: 'Divider' },
					// },
					{
						direction: 'right',
						name: 'form-actions2',
						type: 'inline',

						fields: [
							// {
							// 	name: 'reset',
							// 	type: 'reset',
							// },
							{
								name: 'submit',
								title: 'Save',
								type: 'submit',
							},
						],
					},
				],

				onChange: ({ values, setFieldValue }) => {
					if (values?.schema) {
						const resolve = resolveDataSchema(values.schema);
						setDataType(resolve.dataType);
						setFieldValue('resolvedDataType', resolve.dataType);
					}

					if (onChange) {
						const output = values?.schema ? fromFormValues(values) : values?.schema;
						onChange(output);
					}
				},
			}}
		/>
	);
};

DataItemDefinitionGraphqlForm.defaultProps = {
};

DataItemDefinitionGraphqlForm.displayName = 'DataItemDefinitionGraphqlForm';
