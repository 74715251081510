import { useMutation, useQuery } from '@apollo/client';
import {
	Button,
	ComponentState,
	Divider,
	StatefulComponent,
	View,
} from '@bluebase/components';
import { useNavigation } from '@bluebase/core';
import {
	removeTypeName,
	TraitQuery,
	TraitQueryQuery,
	TraitUiType,
	TraitUpdateMutation,
	TraitUpdateMutationMutation
} from '@mevris/client-graphql';
import React, { useCallback } from 'react';

import { TraitUIEmptyState } from './TraitUIEmptyState';
import { TraitProfileStateItem } from './TraitUIListItem';

export interface TraitUIListProps {
	traitId: string;
	type: TraitUiType;
}

export const TraitUIList = (props: TraitUIListProps) => {
	const { traitId, type } = props;
	const { push } = useNavigation();

	if (!traitId) {
		return <ComponentState description="No Trait ID given" />;
	}

	const { loading, error, data } = useQuery<TraitQueryQuery>(
		TraitQuery,
		{ variables: { id: traitId } }
	);

	const [
		mutate, { loading: mutating }
	] = useMutation<TraitUpdateMutationMutation>(
		TraitUpdateMutation,
		{
			update: (cache) => {
				cache.evict({ fieldName: 'traits' });
				cache.evict({ id: `Trait:${traitId}` });
			}
		}
	);

	const trait: TraitQueryQuery['trait'] = removeTypeName(data?.trait);
	const items = trait?.ui || [];

	const goToAddScreen = useCallback(() => {
		push('TraitUIAdd', { traitId, type });
	}, [traitId, push]);

	const goToEditScreen = useCallback((index: number) => () => {
		push('TraitUIEdit', { traitId, index });
	}, [traitId, push]);

	const removeItem = useCallback((index: number) => () => {
		const newItems = [...(trait?.ui || [])];
		newItems.splice(index, 1);

		mutate({
			variables: {
				id: traitId,
				dto: {
					...trait,
					ui: newItems
				}
			}
		});
	}, [traitId, trait]);

	return (
		<View>
			<StatefulComponent
				loading={loading}
				error={error}
				data={items.filter((item) => item.type === type)}
				delay={0}
				emptyComponent={TraitUIEmptyState}
			>
				{
					items.map((definition, index) => (
						type === definition.type
							?
							(
								<>
									<TraitProfileStateItem
										definition={definition}
										disabled={mutating}
										onEdit={goToEditScreen(index)}
										onRemove={removeItem(index)}
									/>
									{index < items.length - 1 && <Divider />}
								</>
							)
							: null
					))
				}
			</StatefulComponent>
			<Divider />
			<Button
				title="Add UI"
				variant="text"
				icon={{ type: 'icon', name: 'plus' }}
				onPress={goToAddScreen}
			/>
		</View>
	);
};

TraitUIList.defaultProps = {};
TraitUIList.displayName = 'TraitUIList';
