import { useQuery } from '@apollo/client';
import { StatefulComponent } from '@bluebase/components';
import { useNavigation } from '@bluebase/core';
import { BrandDeleteMutation, BrandQuery, BrandQueryQuery } from '@mevris/client-graphql';
import * as React from 'react';

import { DangerSettingAction } from '../../imports';
import { LoadingState } from './LoadingState';

export interface BrandDeleteSettingProps {
	brandId: string;
}

export const BrandDeleteSetting = ({ brandId }: BrandDeleteSettingProps) => {
	const { navigate } = useNavigation();
	const { data, error, loading } = useQuery<BrandQueryQuery>(BrandQuery, { variables: { id: brandId } });

	const onSuccess = React.useCallback(() => {
		navigate('BrandList');
	}, [navigate]);

	return (
		<StatefulComponent data={data?.brand.name} loading={loading} error={error} loadingComponent={LoadingState}>
			<DangerSettingAction
				code={data?.brand.name!}
				onSuccess={onSuccess}
				mutation={{
					mutation: BrandDeleteMutation,
					variables: {
						data: {
							id: brandId,
						},
					} as any,
					update: (cache) => {
						cache.evict({ fieldName: 'brands' });
						cache.evict({ id: `Brand:${brandId}` });
					}
				}}
				schema={{ initialValues: { id: brandId, code: '' } }}
				iconProps={{ name: 'delete' }}
				title="Delete"
				description="Delete this brand permanently. This action is irreversible."
				formTitle="Delete Brand"
				formButtonTitle="Delete this brand"
				formDescription="Type the name of the brand to confirm deletion."
				formTextFieldLabel="Brand Name"
			/>
		</StatefulComponent>
	);
};

BrandDeleteSetting.displayName = 'BrandDeleteSetting';
