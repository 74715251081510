import { Body2, IconButton, List, Text } from '@bluebase/components';
import { useTheme } from '@bluebase/core';
import { TraitState } from '@mevris/client-graphql';
import { resolveDataSchema } from '@mevris/universal-data-schema';
import React from 'react';
import { TextStyle } from 'react-native';

import { DeleteIconButton } from '../../imports';

export interface TraitProfileStateItemStyles {
	descriptionWrapper: TextStyle;
	description: TextStyle;
	badgeWrapper: TextStyle;
	badge: TextStyle;
	badgeGreen: TextStyle;
	badgeOrange: TextStyle;
}
export interface TraitProfileStateItemProps {
	definition: TraitState;
	disabled?: boolean;
	onEdit: () => void;
	onRemove: () => void;
	onSimulate: () => void;
}

export const TraitProfileStateItem = (props: TraitProfileStateItemProps) => {
	const { definition, disabled, onEdit, onRemove, onSimulate } = props;
	const { theme } = useTheme();

	const styles: TraitProfileStateItemStyles = {
		descriptionWrapper: {
			display: 'flex',
			flexDirection: 'column',
		},

		description: {
			color: theme.palette.text.secondary,
		},

		badgeWrapper: {
			paddingVertical: theme.spacing.unit / 2,
		},

		badge: {
			backgroundColor: theme.palette.success.light,
			color: theme.palette.success.contrastText,
			padding: theme.spacing.unit / 2,
			borderRadius: theme.shape.borderRadius,
			marginRight: theme.spacing.unit,
			fontSize: 10
		},

		badgeGreen: {
			backgroundColor: theme.palette.success.light,
			color: theme.palette.success.contrastText,
		},

		badgeOrange: {
			backgroundColor: theme.palette.warning.light,
			color: theme.palette.warning.contrastText,
		},
	};

	const resolved = resolveDataSchema(definition.schema);

	return (
		<List.Item
			key={definition.key}
			title={definition.name}
			description={
				<Text style={styles.descriptionWrapper}>
					<Body2 style={styles.description}>{definition.description}</Body2>
					<Text style={styles.badgeWrapper}>
						<Body2 style={[styles.badge, styles.badgeGreen]}>{definition.key}</Body2>
						<Body2 style={[styles.badge, styles.badgeOrange]}>{resolved.dataType}</Body2>
					</Text>
				</Text>
			}
			disabled={disabled}
			right={
				<>
					<IconButton
						name="test-tube"
						size={20}
						color={theme.palette.text.secondary}
						onPress={onSimulate}
					/>
					<IconButton
						name="pencil"
						size={20}
						color={theme.palette.text.secondary}
						onPress={onEdit}
					/>
					<DeleteIconButton size={20} onDelete={onRemove} />
				</>
			}
		/>
	);
};

TraitProfileStateItem.defaultProps = {};
TraitProfileStateItem.displayName = 'TraitProfileStateItem';
