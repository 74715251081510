import { useComponent } from '@bluebase/core';
import { JsonFormProps } from '@bluebase/plugin-json-schema-components';
import { DataItemDefinition } from '@mevris/client-graphql';
import { DataSchema, DataType, Presets, resolveDataSchema } from '@mevris/universal-data-schema';
import React, { useState } from 'react';

import { AutoKeyGenerator, JsonInputField } from '../../form-fields';
import { fromFormValues } from './fromFormValues';
import { getFieldType } from './getFieldType';
import { NestedField } from './NestedField';
import { toFormValues } from './toFormValues';

export interface DataItemDefinitionFormProps {
	definition: DataItemDefinition,
	onChange?: (schema: DataSchema) => void,
	onSubmit?: (schema: DataSchema) => void,
}

export const DataItemDefinitionForm = (props: DataItemDefinitionFormProps) => {
	const { definition, onChange, onSubmit } = props;
	const JsonForm = useComponent<JsonFormProps<DataSchema>>('JsonForm');

	const resolvedSchema = resolveDataSchema(definition?.schema);
	const [dataType, setDataType] = useState<DataType | undefined>(resolvedSchema.dataType || 'STRING');

	const initialValues = toFormValues(definition);

	return (
		<JsonForm
			schema={{
				fieldTypes: {
					json: [JsonInputField],
					nested: [NestedField],
					'auto-key-generator': [AutoKeyGenerator]
				},

				fields: [
					{
						label: 'Name',
						name: 'name',
						type: 'text',
						required: true,
						// placeholder: resolvedSchema.title,
					},
					{
						label: 'Key',
						name: 'key',
						type: 'text',
						// placeholder: resolvedSchema.key,
						required: true,
					},
					{
						type: 'auto-key-generator',
						name: 'auto-key-generator',
					},
					{
						label: 'Description',
						name: 'Description',
						type: 'text',
						// placeholder: resolvedSchema.Description,
					},
					{
						label: 'Extends',
						name: 'extends',
						type: 'picker',
						placeholder: resolvedSchema.extends,

						items: [
							{
								label: '',
								value: undefined,
							},
							...Object.keys(Presets).map(preset => ({
								label: preset,
								value: preset,
							}))
						],
					},
					{
						label: 'Data Type',
						name: 'dataType',
						type: 'picker',

						items: [
							{
								label: '',
								value: undefined,
							},
							{
								label: 'Boolean',
								value: 'BOOLEAN',
							},
							{
								label: 'Date',
								value: 'DATE',
							},
							{
								label: 'Number',
								value: 'NUMBER',
							},
							{
								label: 'String',
								value: 'STRING',
							},
						],
					},

					{
						name: 'number-max-min',
						type: 'inline',
						displayOptions: {
							show: {
								dataType: ['NUMBER'],
							}
						},

						fields: [
							{
								label: 'Minimum Value',
								name: 'minimumValue',
								type: 'number',
							},
							{
								label: 'Maximum Value',
								name: 'maximumValue',
								type: 'number',
							},
						],
					},
					{
						name: 'number-step-precision',
						type: 'inline',
						displayOptions: {
							show: {
								dataType: ['NUMBER'],
							}
						},

						fields: [
							{
								label: 'Step',
								name: 'step',
								type: 'number',
							},
							{
								label: 'Precision',
								name: 'precision',
								type: 'number',
							},
						],
					},

					{
						name: 'defaults',
						type: 'inline',

						fields: [
							{
								label: 'Default Value',
								name: 'defaultValue',
								type: getFieldType(dataType),
								placeholder: resolvedSchema.defaultValue,

								items: dataType === 'BOOLEAN' ? [
									{
										label: '',
										value: undefined,
									},
									{
										label: 'True',
										value: true,
									},
									{
										label: 'False',
										value: false,
									},
								] : [],
							},
							{
								label: 'Unit',
								name: 'unit',
								type: 'text',
								placeholder: resolvedSchema.unit,
								displayOptions: {
									show: {
										dataType: ['NUMBER'],
									}
								}
							},
						],
					},

					{
						name: 'availableUnits',
						type: 'array',
						label: 'Available Units',
						addButtonLabel: 'Add Unit',

						fields: [
							{
								label: 'Unit',
								name: 'unit',
								type: 'text',
							},
						],
						displayOptions: {
							show: {
								dataType: ['NUMBER'],
							}
						}
					},

					{
						name: 'string-length-inline',
						type: 'inline',
						displayOptions: {
							show: {
								dataType: ['STRING'],
							}
						},

						fields: [
							{
								label: 'Maximum Length',
								name: 'maximumLength',
								type: 'number',
							},
							{
								label: 'Minimum Length',
								name: 'minimumLength',
								type: 'number',
							},
						],
					},

					{
						name: 'values',
						type: 'array',
						label: 'Values',
						addButtonLabel: 'Add Value',

						fields: [
							{
								label: 'Value',
								name: 'value',
								type: getFieldType(dataType),
								required: true,

								items: dataType === 'BOOLEAN'
									? [
										{
											label: 'True',
											value: true,
										},
										{
											label: 'False',
											value: false,
										},
									]
									: [],
							},
							{
								label: 'Label',
								name: 'label',
								type: 'text',
								required: true,
							},
							{
								label: 'Description',
								name: 'description',
								type: 'text',
							},
						],
					},

					{
						label: 'Required',
						name: 'required',
						type: 'checkbox',
					},

					{
						name: 'array-inline',
						type: 'inline',

						fields: [
							{
								label: 'Array',
								name: 'array',
								type: 'checkbox',
							},
							{
								label: 'Minimum Items',
								name: 'minimumItems',
								type: 'number',
								displayOptions: {
									show: {
										array: [true],
									}
								}
							},
							{
								label: 'Maximum Items',
								name: 'maximumItems',
								type: 'number',
								displayOptions: {
									show: {
										array: [true],
									}
								}
							},
						],
					},

					{
						name: 'validators',
						type: 'array',
						label: 'Validators',
						addButtonLabel: 'Add Validator',

						fields: [
							{
								name: 'item',
								type: 'nested',
							},
						],
					},

					{
						name: 'transformers',
						type: 'array',
						label: 'Transformers',
						addButtonLabel: 'Add Transformer',

						fields: [
							{
								name: 'item',
								type: 'nested',
							},
						],
					},

					{
						name: 'printer',
						type: 'group',
						label: 'Printer',

						fields: [
							{
								name: 'printer',
								type: 'nested',
							},
						],
					},

					{
						direction: 'right',
						name: 'form-actions2',
						type: 'inline',

						fields: [
							{
								name: 'submit',
								title: 'Submit',
								type: 'submit',
							},
						],
					},
				],

				initialValues,

				onSubmit: (values) => {
					if (onSubmit){
						onSubmit(fromFormValues(values as any));
					}
				},

				onChange: (ctx) => {
					const values = fromFormValues(ctx.values as any);
					setDataType(values.schema.dataType);

					if (onChange){
						onChange(values);
					}
				},
			}}
		/>
	);
};

DataItemDefinitionForm.defaultProps = {
};

DataItemDefinitionForm.displayName = 'DataItemDefinitionForm';
