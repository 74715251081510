import { IconButton } from '@bluebase/components';
import { useNavigation, useTheme } from '@bluebase/core';
import React, { useCallback } from 'react';

export interface TraitCreateButtonProps {}

export const TraitCreateButton = (_props: TraitCreateButtonProps) => {
	const { theme } = useTheme();
	const { navigate } = useNavigation();

	const onPress = useCallback(() => {
		navigate('TraitCreate');
	}, []);

	return (
		<IconButton
			name="plus"
			onPress={onPress}
			color={theme.palette.text.secondary}
		/>
	);
};

TraitCreateButton.displayName = 'TraitCreateButton';
