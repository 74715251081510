import { useComponent } from '@bluebase/core';
import { JsonFormProps } from '@bluebase/plugin-json-schema-components';
import { TraitStateCommunicationType } from '@mevris/client-graphql';
import React from 'react';

export interface StateCommunicationTypeSettingsFormProps {
	communicationType?: TraitStateCommunicationType,
	onChange?: (communicationType?: TraitStateCommunicationType) => void,
}

export const StateCommunicationTypeSettingsForm = (props: StateCommunicationTypeSettingsFormProps) => {
	const { communicationType, onChange } = props;

	// eslint-disable-next-line max-len
	const JsonForm = useComponent<JsonFormProps<{ communicationType: TraitStateCommunicationType | undefined }>>('JsonForm');

	return (
		<JsonForm
			schema={{

				initialValues: {
					communicationType,
				},

				fields: [
					{
						label: 'Communication Type',
						name: 'communicationType',
						type: 'picker',
						helperText: 'The direction of data flow between device and the cloud.',

						items: [
							{
								label: 'Two Way',
								value: TraitStateCommunicationType.TwoWay,
							},
							{
								label: 'Command Only',
								value: TraitStateCommunicationType.CommandOnly,
							},
							{
								label: 'Query Only',
								value: TraitStateCommunicationType.QueryOnly,
							}
						],
					},
				],

				onSubmit: () => {},

				onChange: (ctx) => {
					const values = ctx.values;

					if (onChange) {
						onChange(values.communicationType);
					}
				},
			}}
		/>
	);
};

StateCommunicationTypeSettingsForm.defaultProps = {
};

StateCommunicationTypeSettingsForm.displayName = 'StateCommunicationTypeSettingsForm';
