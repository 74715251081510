import { useQuery } from '@apollo/client';
import { StatefulComponent } from '@bluebase/components';
import { useNavigation } from '@bluebase/core';
import { ProductDeleteMutation, ProductQuery, ProductQueryQuery } from '@mevris/client-graphql';
import * as React from 'react';

import { DangerSettingAction } from '../../imports';
import { LoadingState } from './LoadingState';

export interface ProductDeleteSettingProps {
	productId: string;
}

export const ProductDeleteSetting = ({ productId }: ProductDeleteSettingProps) => {
	const { navigate } = useNavigation();
	const { data, error, loading } = useQuery<ProductQueryQuery>(ProductQuery, { variables: { id: productId } });

	const onSuccess = React.useCallback(() => {
		navigate('ProductList');
	}, [navigate]);

	return (
		<StatefulComponent data={data?.product.name} loading={loading} error={error} loadingComponent={LoadingState}>
			<DangerSettingAction
				code={data?.product.name!}
				onSuccess={onSuccess}
				mutation={{
					mutation: ProductDeleteMutation,
					variables: {
						data: {
							id: productId,
						},
					} as any,
					update: (cache) => {
						cache.evict({ fieldName: 'Products' });
						cache.evict({ id: `Product:${productId}` });
					}
				}}
				schema={{ initialValues: { id: productId, code: '' } }}
				iconProps={{ name: 'delete' }}
				title="Delete"
				description="Delete this product permanently. This action is irreversible."
				formTitle="Delete Product"
				formButtonTitle="Delete this product"
				formDescription="Type the name of the product to confirm deletion."
				formTextFieldLabel="Product Name"
			/>
		</StatefulComponent>
	);
};

ProductDeleteSetting.displayName = 'ProductDeleteSetting';
