import { DataItemDefinition } from '@mevris/client-graphql';
import { resolveDataSchema } from '@mevris/universal-data-schema';

export function fromFormValues(input: DataItemDefinition): DataItemDefinition {
	const copied = JSON.parse(JSON.stringify(input));

	const schema = copied.schema;
	const resolvedSchema = resolveDataSchema(schema);

	if (schema?.availableUnits) {
		schema.availableUnits = schema?.availableUnits.map((v: any) => v.unit);
	}

	if (schema?.validators && schema?.validators.length > 0) {
		schema.validators = schema.validators.map((x: any) => x.item);
	}

	if (schema?.transformers && schema?.transformers.length > 0) {
		schema.transformers = schema.transformers.map((x: any) => x.item);
	}

	if (schema?.converters && schema?.converters.length > 0) {
		schema.converters = schema.converters.map((x: any) => x.item);
	}

	// Convert to number

	if (schema.minimumValue) {
		schema.minimumValue = Number(schema.minimumValue);
	}

	if (schema.maximumValue) {
		schema.maximumValue = Number(schema.maximumValue);
	}

	if (schema.step) {
		schema.step = Number(schema.step);
	}

	if (schema.precision) {
		schema.precision = Number(schema.precision);
	}

	if (schema.defaultValue) {
		schema.defaultValue = castValue(schema.defaultValue, resolvedSchema.dataType);
	}

	if (schema.values) {
		schema.values = schema.values.map((x: any) => ({
			...x,
			value: castValue(x.value, resolvedSchema.dataType),
		}));
	}

	return {
		...copied,
		schema,
	};
}

function castValue(value: any, dataType?: string) {
	if (dataType === 'DATE') {
		return new Date(value);
	}
	if (dataType === 'BOOLEAN') {
		return Boolean(value);
	} else if (dataType === 'NUMBER') {
		return Number(value);
	} else if (dataType === 'STRING') {
		return String(value);
	}

	return value;
}
