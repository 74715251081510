import { Body2, Subtitle1, TextInput, View } from '@bluebase/components';
import { useStyles, useTheme } from '@bluebase/core';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { TextStyle, ViewStyle } from 'react-native';

export interface FileInputFieldStyles {
	root: ViewStyle;
	titleWrapper: ViewStyle;
	label: TextStyle;
	helperText: TextStyle;
	fieldWrapper: ViewStyle;
	errorWrapper: ViewStyle;
	error: TextStyle;
}

export interface FileInputFieldProps {
	type: 'file',
	name: string,
	label: string;
	required?: boolean;
	helperText?: string;
	schema?: any;
	style?: ViewStyle;
	styles?: Partial<FileInputFieldStyles>;
}

export const FileInputField = (props: FileInputFieldProps) => {

	const { name, style, label, helperText, required } = props;

	const { theme } = useTheme();

	const [, { error }, helpers] = useField(props as any);
	const { setValue: setFormValue } = helpers;

	const styles = useStyles<FileInputFieldStyles>('FileInputField', props, {
		root: {
			paddingVertical: theme.spacing.unit,
		},

		titleWrapper: {
			paddingHorizontal: theme.spacing.unit * 2,
			paddingVertical: theme.spacing.unit,
		},

		label: {
			color: error ? theme.palette.error.main : theme.palette.text.secondary,
		},

		helperText: {
			...theme.typography.caption,
			color: theme.palette.text.secondary,
			paddingTop: theme.spacing.unit / 2,
		},

		fieldWrapper: {
			marginHorizontal: theme.spacing.unit * 2,
			marginVertical: theme.spacing.unit,
		},

		errorWrapper: {
			paddingHorizontal: theme.spacing.unit * 2,
			paddingVertical: theme.spacing.unit,
		},

		error: {
			color: theme.palette.error.main,
		},
	});

	const onChange = useCallback((e: any) => {
		const file = e.target.files[0];
		setFormValue(file);
	}, []);

	return (
		<View style={[styles.root, style]} key={name}>
			<View style={styles.titleWrapper}>
				<Subtitle1 style={styles.label}>{label}{required ? '*' : ''}</Subtitle1>
				{helperText ? <Body2 style={styles.helperText}>{helperText}</Body2> : null}
			</View>
			<View style={styles.fieldWrapper}>
				<TextInput type="file" onChange={onChange} />
			</View>
			{
				error ? (
					<View style={styles.errorWrapper}>
						<Body2 style={styles.error}>{error}</Body2>
					</View>
				) : null
			}
		</View>
	);
};

FileInputField.defaultProps = {
};

FileInputField.displayName = 'FileInputField';
