import { ComponentState } from '@bluebase/components';
import React from 'react';

export const ComingSoon = () => {
	return (
		<ComponentState title="Coming Soon" description="This feature will be added soon" />
	);
};

ComingSoon.defaultProps = {};
ComingSoon.displayName = 'ComingSoon';
