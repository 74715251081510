import { useQuery } from '@apollo/client';
import { StatefulComponent } from '@bluebase/components';
import { useNavigation } from '@bluebase/core';
import { DeviceTypeDeleteMutation, DeviceTypeQuery, DeviceTypeQueryQuery } from '@mevris/client-graphql';
import * as React from 'react';

import { DangerSettingAction } from '../../imports';
import { LoadingState } from './LoadingState';

export interface DeviceTypeDeleteSettingProps {
	deviceTypeId: string;
}

export const DeviceTypeDeleteSetting = ({ deviceTypeId }: DeviceTypeDeleteSettingProps) => {
	const { navigate } = useNavigation();
	const { data, error, loading } = useQuery<DeviceTypeQueryQuery>(
		DeviceTypeQuery,
		{ variables: { id: deviceTypeId } }
	);

	const onSuccess = React.useCallback(() => {
		navigate('DeviceTypeList');
	}, [navigate]);

	return (
		<StatefulComponent data={data?.deviceType.name} loading={loading} error={error} loadingComponent={LoadingState}>
			<DangerSettingAction
				code={data?.deviceType.name!}
				onSuccess={onSuccess}
				mutation={{
					mutation: DeviceTypeDeleteMutation,
					variables: {
						data: {
							id: deviceTypeId,
						},
					} as any,
					update: (cache) => {
						cache.evict({ fieldName: 'deviceTypes' });
						cache.evict({ id: `DeviceType:${deviceTypeId}` });
					}
				}}
				schema={{ initialValues: { id: deviceTypeId, code: '' } }}
				iconProps={{ name: 'delete' }}
				title="Delete"
				description="Delete this device type permanently. This action is irreversible."
				formTitle="Delete DeviceType"
				formButtonTitle="Delete this device type"
				formDescription="Type the name of the device type to confirm deletion."
				formTextFieldLabel="Device Type Name"
			/>
		</StatefulComponent>
	);
};

DeviceTypeDeleteSetting.displayName = 'DeviceTypeDeleteSetting';
